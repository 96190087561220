var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('h1',{staticClass:"title has-text-centered"},[_vm._v("Регистрация")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Фамилия","type":{
            'is-danger': _vm.$v.lastName.$error || 'lastName' in _vm.serverValid,
            'is-success': !_vm.$v.lastName.$invalid && _vm.$v.lastName.$dirty,
          },"message":{ 'Некорректно указана фамилия': _vm.$v.lastName.$error }}},[_c('b-autocomplete',{attrs:{"placeholder":"Фамилия","data":_vm.lastNameData,"keep-first":false,"open-on-focus":false,"clearable":true,"loading":_vm.isFetching},on:{"typing":function($event){return _vm.getDadata($event, 'lastName')},"select":_vm.setGender,"blur":function($event){_vm.onTouchField('lastName');
              _vm.removeKeyFromServerValid('lastName');}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"lastName"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Имя","type":{
            'is-danger': _vm.$v.firstName.$error || 'firstName' in _vm.serverValid,
            'is-success': !_vm.$v.firstName.$invalid && _vm.$v.firstName.$dirty,
          },"message":{ 'Некорректно указано имя': _vm.$v.firstName.$error }}},[_c('b-autocomplete',{attrs:{"placeholder":"Имя","data":_vm.firstNameData,"keep-first":false,"open-on-focus":false,"clearable":true,"loading":_vm.isFetching},on:{"typing":function($event){return _vm.getDadata($event, 'firstName')},"select":_vm.setGender,"blur":function($event){_vm.onTouchField('firstName');
              _vm.removeKeyFromServerValid('firstName');}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"firstName"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Отчество","type":{
            'is-danger': _vm.$v.patronymic.$error || 'patronymic' in _vm.serverValid,
            'is-success': !_vm.$v.patronymic.$invalid && _vm.$v.patronymic.$dirty,
          },"message":{ 'Некорректно указано отчество': _vm.$v.patronymic.$error }}},[_c('b-autocomplete',{attrs:{"placeholder":"Отчество","data":_vm.patronymicData,"keep-first":false,"open-on-focus":false,"clearable":true,"loading":_vm.isFetching},on:{"typing":function($event){return _vm.getDadata($event, 'patronymic')},"select":_vm.setGender,"blur":function($event){_vm.onTouchField('patronymic');
              _vm.removeKeyFromServerValid('patronymic');}},model:{value:(_vm.patronymic),callback:function ($$v) {_vm.patronymic=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"patronymic"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Пол"}},[_c('b-radio-button',{attrs:{"native-value":"0","type":"is-primary","expanded":""},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('b-icon',{attrs:{"icon":"male","size":"is-medium"}}),_c('span',[_vm._v("Мужской")])],1),_c('b-radio-button',{attrs:{"native-value":"1","type":"is-primary","expanded":""},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('b-icon',{attrs:{"icon":"female","size":"is-medium"}}),_c('span',[_vm._v("Женский")])],1)],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Город","type":{
            'is-danger': _vm.$v.addrCity.$error || 'addrCity' in _vm.serverValid,
            'is-success': !_vm.$v.addrCity.$invalid && _vm.$v.addrCity.$dirty,
          },"message":{ 'Некорректно указан город': _vm.$v.addrCity.$error }}},[_c('b-autocomplete',{attrs:{"placeholder":"Город или населенный пункт","data":_vm.addrCityData,"keep-first":false,"open-on-focus":false,"clearable":true,"loading":_vm.isFetching},on:{"typing":function($event){return _vm.getDadata($event, 'addrCity')},"blur":function($event){_vm.onTouchField('addrCity');
              _vm.removeKeyFromServerValid('addrCity');}},model:{value:(_vm.addrCity),callback:function ($$v) {_vm.addrCity=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"addrCity"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Телефон","type":{
            'is-danger': _vm.$v.phone.$error || 'phone' in _vm.serverValid,
            'is-success': !_vm.$v.phone.$invalid && _vm.$v.phone.$dirty,
          },"message":{ 'Телефон в формате +7(900)000-00-00': _vm.$v.phone.$error }}},[_c('IMaskComponent',{staticClass:"input",class:{
              'is-danger': _vm.$v.phone.$error || 'phone' in _vm.serverValid,
              'is-success': !_vm.$v.phone.$invalid && _vm.$v.phone.$dirty,
            },attrs:{"placeholder":"+7(900)000-00-00","maxlength":"16","inputmode":"numeric","mask":'+{7}({9}00)000-00-00'},on:{"blur":function($event){_vm.onTouchField('phone');
              _vm.removeKeyFromServerValid('phone');}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phone"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Email","type":{
            'is-danger': _vm.$v.email.$error || 'email' in _vm.serverValid,
            'is-success': !_vm.$v.email.$invalid && _vm.$v.email.$dirty,
          },"message":{ 'Некорректно указан email': _vm.$v.email.$error }}},[_c('b-input',{attrs:{"placeholder":"example@mail.com"},on:{"blur":function($event){_vm.onTouchField('email');
              _vm.removeKeyFromServerValid('email');}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Пароль","type":{
            'is-danger': _vm.$v.password.$error || 'password' in _vm.serverValid,
            'is-success': !_vm.$v.password.$invalid && _vm.$v.password.$dirty,
          },"message":{ 'Некорректно указан пароль': _vm.$v.password.$error }}},[_c('b-input',{attrs:{"type":"password","placeholder":"","lazy":true,"password-reveal":""},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}})],1)],1),_c('div',{staticClass:"column is-full"},[_c('b-field',[_c('b-checkbox',{attrs:{"type":"is-info"},model:{value:(_vm.agree),callback:function ($$v) {_vm.agree=$$v},expression:"agree"}},[_vm._v("Я ознакомился с "),_c('a',{attrs:{"href":"/doc/agree.pdf"}},[_vm._v("Пользовательским соглашением")]),_vm._v(". "),_c('br'),_vm._v("Также даю свое согласие с "),_c('a',{attrs:{"href":"/doc/uses_conditions.pdf"}},[_vm._v("Условиями использования сервиса")]),_vm._v(", "),_c('a',{attrs:{"href":"/doc/privacy_policy.pdf"}},[_vm._v("Политикой конфиденциальности сервиса")]),_vm._v(".")])],1)],1)]),_c('div',{staticClass:"g-recaptcha",attrs:{"data-sitekey":"6LdpUEkbAAAAACpMAtqivdSajhIlak-vzqDNMdXT"}}),_c('b-button',{attrs:{"native-type":"submit","type":"is-success","expanded":"","icon-left":"user-plus","disabled":!_vm.agree}},[_vm._v("Зарегистрироваться")])],1),_c('div',{staticClass:"login"},[_c('h6',[_c('b-icon',{attrs:{"pack":"fas","icon":"arrow-left","size":"is-medium"}}),_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Перейти на страницу авторизации")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }