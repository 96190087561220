<template>
  <div class="box">
    <h1 class="title has-text-centered">Регистрация</h1>
    <form @submit.prevent="submit">
      <div class="columns is-multiline">
        <div class="column is-full-mobile is-half-tablet is-one-third-desktop">
          <b-field
            label="Фамилия"
            :type="{
              'is-danger': $v.lastName.$error || 'lastName' in serverValid,
              'is-success': !$v.lastName.$invalid && $v.lastName.$dirty,
            }"
            :message="{ 'Некорректно указана фамилия': $v.lastName.$error }"
          >
            <b-autocomplete
              placeholder="Фамилия"
              :data="lastNameData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="lastName"
              @typing="getDadata($event, 'lastName')"
              @select="setGender"
              @blur="
                onTouchField('lastName');
                removeKeyFromServerValid('lastName');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column is-full-mobile is-half-tablet is-one-third-desktop">
          <b-field
            label="Имя"
            :type="{
              'is-danger': $v.firstName.$error || 'firstName' in serverValid,
              'is-success': !$v.firstName.$invalid && $v.firstName.$dirty,
            }"
            :message="{ 'Некорректно указано имя': $v.firstName.$error }"
          >
            <b-autocomplete
              placeholder="Имя"
              :data="firstNameData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="firstName"
              @typing="getDadata($event, 'firstName')"
              @select="setGender"
              @blur="
                onTouchField('firstName');
                removeKeyFromServerValid('firstName');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column is-full-mobile is-half-tablet is-one-third-desktop">
          <b-field
            label="Отчество"
            :type="{
              'is-danger': $v.patronymic.$error || 'patronymic' in serverValid,
              'is-success': !$v.patronymic.$invalid && $v.patronymic.$dirty,
            }"
            :message="{ 'Некорректно указано отчество': $v.patronymic.$error }"
          >
            <b-autocomplete
              placeholder="Отчество"
              :data="patronymicData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="patronymic"
              @typing="getDadata($event, 'patronymic')"
              @select="setGender"
              @blur="
                onTouchField('patronymic');
                removeKeyFromServerValid('patronymic');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column is-full-mobile is-half-tablet is-one-third-desktop">
          <b-field label="Пол">
            <b-radio-button
              v-model="gender"
              native-value="0"
              type="is-primary"
              expanded
            >
              <b-icon icon="male" size="is-medium"></b-icon>
              <span>Мужской</span>
            </b-radio-button>

            <b-radio-button
              v-model="gender"
              native-value="1"
              type="is-primary"
              expanded
            >
              <b-icon icon="female" size="is-medium"></b-icon>
              <span>Женский</span>
            </b-radio-button>
          </b-field>
        </div>
        <div class="column is-full-mobile is-half-tablet is-one-third-desktop">
          <b-field
            label="Город"
            :type="{
              'is-danger': $v.addrCity.$error || 'addrCity' in serverValid,
              'is-success': !$v.addrCity.$invalid && $v.addrCity.$dirty,
            }"
            :message="{ 'Некорректно указан город': $v.addrCity.$error }"
          >
            <b-autocomplete
              placeholder="Город или населенный пункт"
              :data="addrCityData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="addrCity"
              @typing="getDadata($event, 'addrCity')"
              @blur="
                onTouchField('addrCity');
                removeKeyFromServerValid('addrCity');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column is-full-mobile is-half-tablet is-one-third-desktop">
          <b-field
            label="Телефон"
            :type="{
              'is-danger': $v.phone.$error || 'phone' in serverValid,
              'is-success': !$v.phone.$invalid && $v.phone.$dirty,
            }"
            :message="{ 'Телефон в формате +7(900)000-00-00': $v.phone.$error }"
          >
            <IMaskComponent
              placeholder="+7(900)000-00-00"
              maxlength="16"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger': $v.phone.$error || 'phone' in serverValid,
                'is-success': !$v.phone.$invalid && $v.phone.$dirty,
              }"
              :mask="'+{7}({9}00)000-00-00'"
              v-model.trim="phone"
              @blur="
                onTouchField('phone');
                removeKeyFromServerValid('phone');
              "
            />
          </b-field>
        </div>
        <div class="column is-full-mobile is-half-tablet is-one-third-desktop">
          <b-field
            label="Email"
            :type="{
              'is-danger': $v.email.$error || 'email' in serverValid,
              'is-success': !$v.email.$invalid && $v.email.$dirty,
            }"
            :message="{ 'Некорректно указан email': $v.email.$error }"
          >
            <b-input
              placeholder="example@mail.com"
              v-model.trim="email"
              @blur="
                onTouchField('email');
                removeKeyFromServerValid('email');
              "
            ></b-input>
          </b-field>
        </div>
        <div class="column is-full-mobile is-half-tablet is-one-third-desktop">
          <b-field
            label="Пароль"
            :type="{
              'is-danger': $v.password.$error || 'password' in serverValid,
              'is-success': !$v.password.$invalid && $v.password.$dirty,
            }"
            :message="{ 'Некорректно указан пароль': $v.password.$error }"
          >
            <b-input
              type="password"
              placeholder=""
              :lazy="true"
              v-model.trim="$v.password.$model"
              password-reveal
            ></b-input>
          </b-field>
        </div>
        <div class="column is-full">
          <b-field>
            <b-checkbox type="is-info" v-model="agree"
              >Я ознакомился с
              <a href="/doc/agree.pdf">Пользовательским соглашением</a>.
              <br />Также даю свое согласие с
              <a href="/doc/uses_conditions.pdf"
                >Условиями использования сервиса</a
              >,
              <a href="/doc/privacy_policy.pdf"
                >Политикой конфиденциальности сервиса</a
              >.</b-checkbox
            >
          </b-field>
        </div>
      </div>
      <div
        class="g-recaptcha"
        data-sitekey="6LdpUEkbAAAAACpMAtqivdSajhIlak-vzqDNMdXT"
      ></div>
      <b-button
        native-type="submit"
        type="is-success"
        expanded
        icon-left="user-plus"
        :disabled="!agree"
        >Зарегистрироваться</b-button
      >
    </form>
    <div class="login">
      <h6>
        <b-icon pack="fas" icon="arrow-left" size="is-medium"></b-icon>
        <router-link to="/login">Перейти на страницу авторизации</router-link>
      </h6>
    </div>
  </div>
</template>

<script>
import {
  minLength,
  maxLength,
  required,
  email,
} from 'vuelidate/lib/validators';
import API from '@/api';
import DadataAPI from '@/api/dadata';
import debounce from '@/utils/debounce';
import { onTouchField, removeKeyFromServerValid } from '@/utils/helpers';
import { IMaskComponent } from 'vue-imask';

export default {
  name: 'SignUp',
  data() {
    return {
      lastNameData: [],
      firstNameData: [],
      patronymicData: [],
      addrCityData: [],
      isFetching: false,
      phone: '',
      firstName: '',
      lastName: '',
      patronymic: '',
      gender: '',
      email: '',
      addrCity: '',
      password: '',
      agree: false,
      role: 1,
      onTouchField,
      removeKeyFromServerValid,
      serverValid: {},
    };
  },
  created() {
    const recaptcha = document.createElement('script');
    recaptcha.async = true;
    recaptcha.defer = true;
    recaptcha.src = 'https://www.google.com/recaptcha/api.js';
    document.head.appendChild(recaptcha);

    this.$store.commit('toggleLoader', false);
  },
  methods: {
    setGender(option) {
      if (option !== null) {
        this.gender =
          option.data.gender !== 'UNKNOWN'
            ? option.data.gender === 'MALE'
              ? '0'
              : '1'
            : this.gender;
      }
    },
    getDadata: debounce(async function (value, field) {
      if (!value.length) {
        this[field + 'Data'] = [];
        return;
      }

      this.isFetching = true;

      let data = {};

      switch (field) {
        case 'lastName':
        case 'firstName':
        case 'patronymic':
          data = await DadataAPI.getFIO({
            value,
            type: field,
            gender: this.gender,
          });
          break;

        case 'addrRegion':
        case 'addrCity':
        case 'addrStreet':
        case 'addrHouse':
          data = await DadataAPI.getAddress({
            value,
            type: field,
            location: {},
          });
          break;

        default:
          break;
      }

      this[field + 'Data'] = [];

      if (data.length) {
        if (field === 'addrCity') {
          data.forEach((item) => {
            item.value =
              item.data.region_with_type + ', ' + item.data.city_with_type;
          });
        }

        data.forEach((item) => this[field + 'Data'].push(Object.freeze(item)));
      }

      this.isFetching = false;
    }, 500),
    async submit() {
      this.$v.$touch();

      /*global grecaptcha*/
      if (grecaptcha.getResponse().length === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Пройдите проверку КАПЧИ',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        return;
      }

      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit('toggleLoader', true);

      const data = await API.newUser({
        userData: {
          phone: this.phone,
          firstName: this.firstName,
          lastName: this.lastName,
          patronymic: this.patronymic,
          gender: this.gender,
          email: this.email,
          addrCity: this.addrCity,
          password: this.password,
          role: this.role,
        },
      });

      this.$store.commit('toggleLoader', false);

      if ('noValid' in data) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Произошла ошибка. Проверьте правильность заполенных полей',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        return;
      }

      if ('response' in data && data.response.status === 500) {
        this.$buefy.toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        return;
      }

      if (data.success) {
        this.$buefy.toast.open({
          duration: 5000,
          message:
            'Регистрация прошла успешно. На ваш электронный адрес отправлено письмо с ссылкой для подтверждения email.',
          position: 'is-top',
          type: 'is-success',
        });

        // this.$router.push('/login');
        setTimeout(() => {
          window.location.href = '/login';
        }, 5000);
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: data.message || 'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        return;
      }
    },
  },
  validations: {
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(40),
      required,
    },
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(40),
      required,
    },
    patronymic: {
      minLength: minLength(2),
      maxLength: maxLength(40),
      required,
    },
    phone: {
      minLength: minLength(16),
      maxLength: maxLength(16),
      required,
    },
    addrCity: {
      required,
      minLength: minLength(2),
    },
    email: {
      required,
      email,
      maxLength: maxLength(40),
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(30),
    },
  },
  components: { IMaskComponent },
};
</script>

<style lang="scss" scoped>
.box {
  width: 98%;
  background: #fff;
  padding: 30px;
  color: #17a2b7;
  margin: 0 auto;

  h1 {
    font-weight: 500;
  }

  form {
    width: 100%;

    .checkbox {
      color: #4a4a4a;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    button {
      max-width: 300px;
      margin: 20px auto 0;
    }

    .g-recaptcha {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .login {
    margin-top: 20px;

    h6 {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        font-weight: bold;
      }
    }
  }
}
</style>
